import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'lib'

const PageTitle = (props) => {
  const { title, position } = props

  return (
    <Row justify={position} gutter={20}>
      <Col>
        <h4>{title}</h4>
      </Col>
    </Row>
  )
}

PageTitle.defaultProps = {
  title: null,
  position: 'center'
}

PageTitle.propTypes = {
  title: PropTypes.node,
  position: PropTypes.string
}

export default PageTitle
