import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-scroll'

const ScrollLink = ({ children, linkTo, className, ...props }) => {
  return (
    <Link
      to={linkTo}
      smooth
      duration={1000}
      spy
      className={className}
      activeClass={`${className}-active`}
      offset={-25}
      {...props}
    >
      {children}
    </Link>
  )
}

ScrollLink.defaultProps = {
  className: 'menu-link'
}

ScrollLink.propTypes = {
  children: PropTypes.node.isRequired,
  linkTo: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func
}

export default ScrollLink
