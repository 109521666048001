import React from 'react'
import { FormattedMessage } from 'react-intl'

const RULES = {
  required: {
    required: true,
    message: <FormattedMessage id="required" />
  }
}

export default RULES
