import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import 'assets/themes/index.less'
import 'antd/dist/antd.css'
import 'draft-js/dist/Draft.css'

import IntlProvider from 'app/i18n/IntlProvider'

import routes from 'pages/routes'

import history from 'app/history'
import store from 'app/store'
import * as serviceWorker from './serviceWorker'

const bootstrap = async () => {
  ReactDOM.render(
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <IntlProvider>{routes}</IntlProvider>
      </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
  )

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister()
}
bootstrap()
