import React, { useEffect, useState, useCallback } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Modal,
  Form,
  PageTitle,
  Row,
  Col,
  Skeleton,
  Button,
  Editor,
  Upload,
  Icon,
  ICONS_LIST,
  message
} from 'lib'

import loadingSelector from 'utils/redux/selectors'
import RULES from 'utils/form/rules'
import { FILERENDER_URL } from 'utils/redux/request'

import {
  fetchPublication,
  fetchPublications,
  createPublication,
  resetPublication,
  updatePublication,
  setPublicationsFilters
} from 'store/actions'
import { FETCH_PUBLICATION, CREATE_PUBLICATION, UPDATE_PUBLICATION } from 'store/constants'

const acceptedExtensions = ['.jpg', '.jpeg', '.png', '.tif', '.tiff', '.gif', '.svg', '.bmp']
const StyledUpload = styled(Upload)`
  && .ant-upload-select-picture-card {
    width: 100%;
    height: 134px;
  }
`

const PublicationModalForm = ({ intl, actions, publication, ...props }) => {
  const [form] = Form.useForm()
  const [formState, setForm] = useState({})
  const [pictures, setPictures] = useState([])
  const [previewPicture, setPreviewPicture] = useState()
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false)

  const handleCancelPreview = useCallback(() => {
    setModalPreviewVisible(false)
  }, [])

  const { id, visible, onCancel, isFetching, isSaving } = props

  useEffect(() => {
    if (visible) {
      actions.initData()
    }
  }, [visible])

  useEffect(() => {
    setForm({
      ...publication
    })
    setPictures(
      publication.images
        ? publication.images.map((image) => ({
            ...image,
            url: image.fileUrl,
            uid: image.name,
            status: 'done'
          }))
        : []
    )
  }, [publication])

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...formState,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await form.validateFields().then(() => actions.onSubmit({ ...formState, images: pictures }))
    onCancel()
  }

  const handleAfterClose = async () => {
    await actions.resetData()
    form.resetFields()
    setPictures([])
  }

  const isFileAccepted = (file) => {
    if (file) {
      if (
        (file.fileExtension && acceptedExtensions.includes(`.${file.fileExtension}`)) ||
        acceptedExtensions.includes(`.${file.name.split('.').pop()}`.toLowerCase())
      ) {
        return true
      }
      return false
    }

    return false
  }

  const handleChangePicture = async (e) => {
    const { file, fileList } = e
    if (file.status === 'done') {
      const { temporaryFile } = file.response
      setPictures([
        ...fileList.filter((x) => x.uid !== file.uid),
        {
          ...file,
          uid: temporaryFile.name,
          url: temporaryFile.fileUrl
        }
      ])
    } else {
      if (file.status === 'error' && file.response && file.response.message) {
        message.error(file.response.message)
      }
      setPictures([...fileList.filter((x) => isFileAccepted(x))])
    }
  }

  const handleRemovePicture = (file) => {
    setPictures([...pictures.filter((x) => x.uid !== file.uid)])
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreview = async (picture) => {
    if (!picture.url) {
      picture.url = await getBase64(picture.originFileObj)
    }
    setPreviewPicture(picture.url)
    setModalPreviewVisible(true)
  }

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={handleAfterClose}
      width={720}
    >
      <Form form={form} onSubmit={handleSubmit} layout="vertical">
        <PageTitle
          title={
            id
              ? intl.formatMessage({ id: 'publications.form.update' })
              : intl.formatMessage({ id: 'publications.form.create' })
          }
        />

        <Skeleton paragraph={false} loading={isFetching} active>
          <Form.Item
            initialValue={publication.descriptionFr}
            label={intl.formatMessage({ id: 'publications.label.descriptionFr' })}
            name="descriptionFr"
            rules={[RULES.required]}
          >
            <Editor
              placeholder={intl.formatMessage({ id: 'publications.label.descriptionFr' })}
              defaultValue={publication.descriptionFr}
              name="descriptionFr"
              onChange={handleChange}
            />
          </Form.Item>
        </Skeleton>

        <Skeleton paragraph={false} loading={isFetching} active>
          <Form.Item
            initialValue={publication.descriptionEn}
            label={intl.formatMessage({ id: 'publications.label.descriptionEn' })}
            name="descriptionEn"
          >
            <Editor
              placeholder={intl.formatMessage({ id: 'publications.label.descriptionEn' })}
              name="descriptionEn"
              defaultValue={publication.descriptionEn}
              onChange={handleChange}
            />
          </Form.Item>
        </Skeleton>

        <Skeleton paragraph={false} loading={isFetching} active>
          <Form.Item
            initialValue={publication.images}
            label={intl.formatMessage({ id: 'publications.label.photos' })}
            name="images"
          >
            <StyledUpload
              headers={{ Authorization: `Bearer ${localStorage.getItem('bearer')}` }}
              action={FILERENDER_URL}
              onChange={handleChangePicture}
              onRemove={handleRemovePicture}
              onPreview={handlePreview}
              method="POST"
              fileList={pictures}
              defaultFileList={pictures}
              showUploadList
              multiple
              listType="picture-card"
              accept="image/*"
              beforeUpload={(file) => isFileAccepted(file)}
            >
              <div>
                <Icon name={ICONS_LIST.plus} />
                <div className="ant-upload-text">
                  <FormattedMessage id="upload" />
                </div>
              </div>
            </StyledUpload>
          </Form.Item>
        </Skeleton>

        <Form.Item>
          <Row align="center" gutter={16}>
            <Col>
              <Button
                onClick={handleSubmit}
                htmlType="submit"
                type="primary"
                loading={isSaving}
                disabled={isSaving}
              >
                <FormattedMessage id="save" />
              </Button>
            </Col>
            <Col>
              <Button onClick={onCancel} htmlType="submit" type="secondary" disabled={isSaving}>
                <FormattedMessage id="cancel" />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <Modal visible={modalPreviewVisible} footer={null} onCancel={handleCancelPreview}>
        <img alt="Preview" style={{ width: '100%' }} src={previewPicture} />
      </Modal>
    </Modal>
  )
}

PublicationModalForm.defaultProps = {
  id: null
}

PublicationModalForm.propTypes = {
  intl: PropTypes.shape().isRequired,
  visible: PropTypes.bool.isRequired,
  id: PropTypes.number,
  actions: PropTypes.shape().isRequired,
  publication: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  publication: state.publication,
  isFetching: loadingSelector([FETCH_PUBLICATION])(state),
  isSaving: loadingSelector([CREATE_PUBLICATION, UPDATE_PUBLICATION])(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    initData: async () => {
      const { id } = ownProps
      if (id) {
        await dispatch(fetchPublication(id))
      }
    },
    resetData: async () => {
      await dispatch(resetPublication())
    },
    onSubmit: async (form) => {
      const { id } = ownProps
      if (id) {
        await dispatch(updatePublication(id, form))
      } else {
        await dispatch(async (_, getState) => {
          const { album } = getState()
          await dispatch(createPublication(form, album.id))
        })
      }
      await dispatch(setPublicationsFilters({ offset: 0, search: null }))
      dispatch(fetchPublications())
    }
  }
})

const withIntl = injectIntl(PublicationModalForm)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
