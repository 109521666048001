import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { List } from 'antd'
import { colors } from 'assets/themes'

import Skeleton from './Skeleton'

const AntListStyled = styled(List)`
  &&& {
    .ant-pagination-item-active {
      border-color: ${colors.red};

      a {
        color: ${colors.red};
      }
    }
    .ant-pagination-item {
      :focus,
      :hover {
        border-color: ${colors.red};
        a {
          color: ${colors.red};
        }
      }
    }
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
      color: ${colors.red};
    }

    .ant-pagination-prev,
    .ant-pagination-next {
      :focus,
      :hover {
        .ant-pagination-item-link {
          border-color: ${colors.red};
          color: ${colors.red};
        }
      }
    }
  }
`

class ListStyled extends React.Component {
  constructor() {
    super()
    this.state = { dataSource: [] }
  }

  componentDidMount() {
    const { pagination } = this.props
    const pageSize = (pagination || {}).pageSize || 3

    this.setState({
      dataSource: Array.from({ length: pageSize }, () => ({}))
    })
  }
  static Item = AntListStyled.Item

  render() {
    const { loading, grid, skeleton, rows } = this.props
    const { dataSource } = this.state

    return loading ? (
      <AntListStyled
        grid={grid}
        dataSource={dataSource}
        renderItem={() => (
          <AntListStyled.Item>
            <Skeleton data-test="list-squeleton" active {...skeleton} paragraph={{ rows }} />
          </AntListStyled.Item>
        )}
        data-test="list-loading"
      />
    ) : (
      <AntListStyled data-test="list" {...this.props} />
    )
  }
}

ListStyled.defaultProps = {
  loading: false,
  grid: null,
  pagination: null,
  skeleton: null,
  rows: 3
}

ListStyled.propTypes = {
  rows: PropTypes.number,
  grid: PropTypes.shape(),
  loading: PropTypes.bool,
  pagination: PropTypes.shape(),
  skeleton: PropTypes.shape()
}

export default ListStyled
