const loadState = (key) => {
  const serializedState = localStorage.getItem(key)
  if (!serializedState) {
    return undefined
  }
  return JSON.parse(serializedState)
}

const saveState = (states) => {
  Object.entries(states).forEach((state) => {
    const serializedState = JSON.stringify(state[1])
    localStorage.setItem(state[0], serializedState)
  })
}

export const persistedState = {
  user: loadState('user'),
  language: loadState('language')
}
export const persistState = (state) => {
  saveState({
    user: state.user,
    language: state.language
  })
}
