export const colors = {
  red: '#5c1411',
  grey: '#251e20',
  white: '#ffffff'
}

export const font = {
  size: {
    xxs: '0.6471rem', //11px
    xs: '0.7647rem', //13px
    sm: '0.8824rem', //15px
    base: '1rem', //17px
    lg: '1.118rem', //19px
    lgxxs: '1.294rem', //22px
    lgxs: '1.588rem', //27px
    lgsm: '1.824rem', //31px
    lgxl: '2.118rem', //36px
    lgxxl: '2.471rem', //42px
    extralg: '4.941rem' //84px
  },
  weight: {
    base: 400,
    bold: 700,
    black: 800
  }
}

export const borderRadius = {
  sm: '6px',
  md: '9px',
  base: '12px',
  lg: '24px'
}
