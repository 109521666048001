import React, { useEffect } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ReactHtmlParser from 'react-html-parser'

import { Modal, Skeleton, Typography, Divider, ImageGallery } from 'lib'

import loadingSelector from 'utils/redux/selectors'

import { fetchPublication, resetPublication } from 'store/actions'
import { FETCH_PUBLICATION } from 'store/constants'

const { Paragraph } = Typography

const PublicationModalForm = ({ intl, actions, publication, ...props }) => {
  const { visible, onCancel, isFetching } = props

  useEffect(() => {
    if (visible) {
      actions.initData()
    }
  }, [visible])

  const handleAfterClose = async () => {
    await actions.resetData()
  }

  return (
    <Modal
      destroyOnClose
      visible={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={handleAfterClose}
      width={720}
    >
      {publication.images.length > 0 && (
        <>
          <ImageGallery
            showPlayButton={false}
            items={publication.images.map((image) => ({
              fullscreen: `${image.fileUrl}`,
              original: `${image.fileUrl}?width=492`,
              thumbnail: `${image.fileUrl}?width=100`
            }))}
            showThumbnails={false}
            showFullscreenButton={false}
            showBullets
          />
          <Divider />
        </>
      )}
      <Skeleton paragraph={false} loading={isFetching} active>
        <Paragraph>{ReactHtmlParser(publication.descriptionFr)}</Paragraph>
      </Skeleton>
      {publication.descriptionEn && (
        <>
          <Divider />
          <Skeleton paragraph={false} loading={isFetching} active>
            <Paragraph ellipsis={{ expandable: true, rows: 2 }}>
              {ReactHtmlParser(publication.descriptionEn)}
            </Paragraph>
          </Skeleton>
        </>
      )}
    </Modal>
  )
}

PublicationModalForm.defaultProps = {
  id: null
}

PublicationModalForm.propTypes = {
  intl: PropTypes.shape().isRequired,
  visible: PropTypes.bool.isRequired,
  id: PropTypes.number,
  actions: PropTypes.shape().isRequired,
  publication: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  publication: state.publication,
  isFetching: loadingSelector([FETCH_PUBLICATION])(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    initData: async () => {
      const { id } = ownProps
      await dispatch(fetchPublication(id))
    },
    resetData: async () => {
      await dispatch(resetPublication())
    }
  }
})

const withIntl = injectIntl(PublicationModalForm)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
