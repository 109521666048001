/**
 * Load local languages
 * @param {String} locale
 */
export default function loadTranslations(locale) {
  switch (locale) {
    case 'en':
      return import('./translations/en_GB.json')
    case 'fr':
      return import('./translations/fr_FR.json')
    default:
      return import('./translations/fr_FR.json')
  }
}
