import React from 'react'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import PropTypes from 'prop-types'

const defaultSpin = ({ color, size, fontSize }) => (
  <Spin size={size} indicator={<LoadingOutlined style={{ fontSize, color }} spin />} />
)

defaultSpin.defaultProps = {
  size: 'large',
  fontSize: 24
}

defaultSpin.propTypes = {
  color: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  fontSize: PropTypes.number
}

export default defaultSpin
