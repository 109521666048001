import styled from 'styled-components'
import { Menu } from 'antd'

const AntMenuStyled = styled(Menu)`
  :not(.ant-dropdown-menu) {
    background: transparent;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    color: #fff;
  }

  :not(.ant-dropdown-menu) {
    color: black;
    font-weight: 500;
  }
`

AntMenuStyled.Item = styled(Menu.Item)``

export default AntMenuStyled
