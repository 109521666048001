import React from 'react'
import { Switch } from 'react-router-dom'

import PrivateRoute from 'utils/route/private-route'
import * as constants from 'utils/route/constants'

import Home from './home'
import Authentication from './authentication'
import Contact from './contact'
import Gallery from './gallery'

const routes = (
  <Switch>
    <PrivateRoute exact path={constants.AUTHENTICATION_SIGN_IN} component={Authentication} />
    <PrivateRoute exact path={constants.CONTACT} component={Contact} />
    <PrivateRoute exact path={constants.GALLERY} component={Gallery} />
    <PrivateRoute path={constants.HOME} component={Home} />
    {/** default always in last */}
  </Switch>
)

export default routes
