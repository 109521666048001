export const CHANGE_LANGUAGE = 'language/CHANGE_LANGUAGE'
export const SIGN_IN = 'authentication/SIGN_IN'
export const SIGN_OUT = 'authentication/SIGN_OUT'
export const SET_SPIN_LOADER = 'SET_SPIN_LOADER'

export const FETCH_CONTACT = 'contact/FETCH_CONTACT'
export const UPDATE_CONTACT = 'contact/UPDATE_CONTACT'

export const FETCH_ALBUMS = 'gallery/FETCH_ALBUMS'
export const FETCH_ALBUM = 'gallery/FETCH_ALBUM'
export const CREATE_ALBUM = 'gallery/CREATE_ALBUM'
export const UPDATE_ALBUM = 'gallery/UPDATE_ALBUM'
export const DELETE_ALBUM = 'gallery/DELETE_ALBUM'
export const RESET_ALBUM = 'gallery/RESET_ALBUM'
export const SET_ALBUMS_FILTERS = 'gallery/SET_ALBUMS_FILTERS'

export const FETCH_PUBLICATIONS = 'gallery/FETCH_PUBLICATIONS'
export const FETCH_PUBLICATION = 'gallery/FETCH_PUBLICATION'
export const CREATE_PUBLICATION = 'gallery/CREATE_PUBLICATION'
export const UPDATE_PUBLICATION = 'gallery/UPDATE_PUBLICATION'
export const DELETE_PUBLICATION = 'gallery/DELETE_PUBLICATION'
export const RESET_PUBLICATION = 'gallery/RESET_PUBLICATION'
export const SET_PUBLICATIONS_FILTERS = 'gallery/SET_PUBLICATIONS_FILTERS'

export const HTTP_BAD_REQUEST = 400
export const HTTP_NOT_FOUND = 404

export const APPLICATION_ID = 'a1f962b9-0203-4d56-9f59-374075056bfd'

export const GALLERY_STEPS = {
  ALBUMS_LIST: 'ALBUMS_LIST',
  PUBLICATIONS_LIST: 'PUBLICATIONS_LIST'
}
