import styled from 'styled-components'
import { Breadcrumb } from 'antd'
import { colors } from 'assets/themes'

const AntBreadcrumbStyled = styled(Breadcrumb)`
  .breadcrumb-hoverable {
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
      text-decoration: underline;
      color: ${colors.red};
    }
  }
`
export default AntBreadcrumbStyled
