import englishFlag from './img/languages/english_flag.svg.png'
import frenchFlag from './img/languages/france_flag.svg.png'
import logo from './img/logo/logo.png'
import logoHead from './img/logo/logo_head.png'
import logoFoot from './img/logo/logo_foot.png'
import noImage from './img/no-image.jpg'

const img = {
  englishFlag: {
    url: englishFlag,
    alt: 'English Flag'
  },
  frenchFlag: {
    url: frenchFlag,
    alt: 'French Flag'
  },
  logo: {
    url: logo,
    alt: 'Logo'
  },
  logoHead: {
    url: logoHead,
    alt: 'Logo Head'
  },
  logoFoot: {
    url: logoFoot,
    alt: 'Logo Foot'
  },
  noImage: {
    url: noImage,
    alt: 'No image'
  }
}

export default img
