import React from 'react'
import PropTypes from 'prop-types'

import { Button, Col, Row } from 'lib'

const SectionTitle = ({ title, subtitle, subtitleAction, subtitleDisabled, children }) => (
  <Row className="section-title" align="middle" justify="space-between" gutter={20}>
    <Col>
      <Row type="flex" align="middle" gutter={12}>
        <Col>
          <h4>{title}</h4>
        </Col>
        {subtitle && subtitleAction && (
          <Col>
            <Button type="link" onClick={subtitleAction} disabled={subtitleDisabled}>
              {subtitle}
            </Button>
          </Col>
        )}
      </Row>
    </Col>

    {children}
  </Row>
)

SectionTitle.defaultProps = {
  title: null,
  subtitle: null,
  subtitleAction: null,
  subtitleDisabled: false,
  children: null
}

SectionTitle.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.string,
  subtitleDisabled: PropTypes.bool,
  subtitleAction: PropTypes.func,
  children: PropTypes.node
}

export default SectionTitle
