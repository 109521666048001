import React, { useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import history from 'app/history'

import { Input, Form, Row, Col, Button } from 'lib'

import loadingSelector from 'utils/redux/selectors'
import { HOME } from 'utils/route/constants'

import { signIn } from 'store/actions'
import { SIGN_IN } from 'store/constants'

const Authentication = ({ intl, actions, ...props }) => {
  const [form] = Form.useForm()
  const [formState, setForm] = useState({
    login: null,
    password: null
  })

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...formState,
      [name]: value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    actions.signIn(formState)
  }

  return (
    <div className="auth">
      <div className="auth-wrapper">
        <h2>
          <FormattedMessage id="auth.connection" />
        </h2>
        <Form form={form} layout="vertical" requiredMark={false} onFinish={handleSubmit}>
          <Form.Item
            label={intl.formatMessage({ id: 'auth.login' })}
            name="login"
            rules={[{ required: true }]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'auth.login' })}
              name="login"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item
            label={intl.formatMessage({ id: 'auth.password' })}
            name="password"
            rules={[{ required: true }]}
          >
            <Input.Password
              placeholder={intl.formatMessage({ id: 'auth.password' })}
              name="password"
              onChange={handleChange}
            />
          </Form.Item>

          <Form.Item>
            <Row align="center">
              <Col>
                <Button
                  onClick={handleSubmit}
                  htmlType="submit"
                  type="primary"
                  disabled={!formState.login || !formState.password}
                  loading={props.loading}
                >
                  <FormattedMessage id="auth.sign_in" />
                </Button>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    </div>
  )
}

Authentication.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  loading: loadingSelector([SIGN_IN])(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    signIn: (auth) => {
      dispatch(async (_, getState) => {
        await dispatch(signIn(auth))

        const { token } = getState().authentication

        if (token) {
          localStorage.setItem('bearer', token)
          history.replace({ pathname: HOME })
        }
      })
    }
  }
})

const withIntl = injectIntl(Authentication)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
