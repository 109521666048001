import React, { useEffect, useState, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Layout } from 'lib'

import loadingSelector from 'utils/redux/selectors'

import { fetchContact } from 'store/actions'
import { FETCH_CONTACT } from 'store/constants'

import ContactForm from './containers/ContactForm'
import ContactDescritpion from './components/ContactDescription'

const Contact = ({ actions, ...props }) => {
  const [isFormShown, setFormShow] = useState(false)
  const show = useCallback(() => setFormShow(true))
  const hide = useCallback(() => setFormShow(false))

  useEffect(() => {
    actions.fetch()
  }, [])

  return (
    <Layout>
      {isFormShown ? (
        <ContactForm onCancel={hide} />
      ) : (
        <ContactDescritpion {...props} showContactForm={show} />
      )}
    </Layout>
  )
}

Contact.propTypes = {
  actions: PropTypes.shape().isRequired
}

const mapStateToProps = (state) => ({
  contact: state.contact,
  loading: loadingSelector([FETCH_CONTACT])(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetch: () => dispatch(fetchContact())
  }
})

export default connect(mapStateToProps, mapDispatchToProps)(Contact)
