import styled from 'styled-components'
import { Input } from 'antd'
import { colors } from 'assets/themes'

const AntInputStyled = styled(Input)`
  border: none;
  border-bottom: 3px solid ${colors.red};
  background-color: transparent;
  border-radius: 0;
  padding: 0 0 10px 0;
  &:hover,
  &:focus {
    border: none;
    border-bottom: 3px solid ${colors.grey};
    border-radius: 0;
    padding: 0 0 10px 0;
    box-shadow: none;
  }
`
AntInputStyled.Search = styled(Input.Search)`
  &&& {
    .ant-input {
      border: none;
      border-bottom: 3px solid ${colors.red};
      background-color: transparent;
      border-radius: 0;
      padding: 0 0 10px 0;
      &:hover,
      &:focus {
        border: none;
        border-bottom: 3px solid ${colors.grey};
        border-radius: 0;
        padding: 0 0 10px 0;
        box-shadow: none;
      }
    }
    .ant-input-search-button {
      border: none;
      border-bottom: 3px solid ${colors.red};
      &:hover,
      &:focus {
        border: none;
        color: ${colors.grey} !important;
        border-bottom: 3px solid ${colors.grey};
        border-radius: 0;
        box-shadow: none;
      }
    }
    .ant-input-group-addon {
      border: none;
      background-color: transparent;
      border-radius: 0;
      padding: 3px;
      left: -3px;
    }
  }
`
AntInputStyled.Password = styled(Input.Password)`
  border: none;
  border-bottom: 3px solid ${colors.red};
  border-radius: 0;
  padding: 0 0 10px 0;
  & > input {
    background-color: transparent;
  }
  &&& {
    background-color: transparent;
  }
  &:hover,
  &:focus {
    border: none;
    border-bottom: 3px solid ${colors.grey};
    border-radius: 0;
    padding: 0 0 10px 0;
    box-shadow: none;
  }
`

AntInputStyled.TextArea = styled(Input.TextArea)`
  border: none;
  border-bottom: 3px solid ${colors.red};
  border-radius: 0;
  padding: 0 0 10px 0;
  &:hover,
  &:focus {
    border: none;
    border-bottom: 3px solid ${colors.grey};
    border-radius: 0;
    padding: 0 0 10px 0;
    box-shadow: none;
  }
`

export default AntInputStyled
