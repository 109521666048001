import { combineReducers } from 'redux'
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router'
import {
  authentication,
  spinLoader,
  user as userReducer,
  language as languageReducer,
  contact,
  albums,
  album,
  publications,
  publication,
  errorReducer,
  loadingReducer
} from 'store/reducer'

import history from './history'

const appReducer = combineReducers({
  authentication,
  user: userReducer,
  contact,
  albums,
  album,
  publications,
  publication,
  language: languageReducer,
  error: errorReducer,
  spinLoader,
  loading: loadingReducer,
  router: connectRouter(history)
})

const rootReducer = (state, action) => {
  if (action.type === LOCATION_CHANGE) {
    const { user, language } = state
    state = { user, language }
  }
  return appReducer(state, action)
}

export default rootReducer
