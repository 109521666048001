import React, { useEffect, useState, useCallback } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from 'styled-components'

import {
  Modal,
  Form,
  PageTitle,
  Row,
  Col,
  Skeleton,
  Button,
  Input,
  Upload,
  Icon,
  ICONS_LIST,
  message
} from 'lib'

import loadingSelector from 'utils/redux/selectors'
import RULES from 'utils/form/rules'
import { FILERENDER_URL } from 'utils/redux/request'

import {
  fetchAlbum,
  fetchAlbums,
  createAlbum,
  resetAlbum,
  updateAlbum,
  setAlbumsFilters
} from 'store/actions'
import { FETCH_ALBUM, CREATE_ALBUM, UPDATE_ALBUM } from 'store/constants'

const acceptedExtensions = ['.jpg', '.jpeg', '.png', '.tif', '.tiff', '.gif', '.svg', '.bmp']
const StyledUpload = styled(Upload)`
  && .ant-upload-select-picture-card {
    width: 100%;
    height: 134px;
  }
`

const AlbumModalForm = ({ intl, actions, album, ...props }) => {
  const [form] = Form.useForm()
  const [formState, setForm] = useState({})
  const [pictures, setPictures] = useState([])
  const [previewPicture, setPreviewPicture] = useState()
  const [modalPreviewVisible, setModalPreviewVisible] = useState(false)

  const handleCancelPreview = useCallback(() => {
    setModalPreviewVisible(false)
  }, [])

  const { id, visible, onCancel, isFetching, isSaving } = props

  useEffect(() => {
    if (visible) {
      actions.initData()
    }
  }, [visible])

  useEffect(() => {
    setForm({
      ...album
    })
    setPictures(
      album.image
        ? [
            {
              ...album.image,
              url: album.image.fileUrl,
              uid: album.image.name,
              status: 'done'
            }
          ]
        : []
    )
  }, [album])

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...formState,
      [name]: value
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await form.validateFields().then(() => actions.onSubmit(formState))
    onCancel()
  }

  const handleAfterClose = async () => {
    await actions.resetData()
    form.resetFields()
    setPictures([])
  }

  const isFileAccepted = (file) => {
    if (file) {
      if (
        (file.fileExtension && acceptedExtensions.includes(`.${file.fileExtension}`)) ||
        acceptedExtensions.includes(`.${file.name.split('.').pop()}`.toLowerCase())
      ) {
        return true
      }
      return false
    }

    return false
  }

  const handleChangePicture = (e) => {
    const { file, fileList } = e
    if (file.status === 'done') {
      const { temporaryFile } = file.response
      setPictures([
        {
          ...file,
          uid: temporaryFile.name,
          url: temporaryFile.fileUrl
        }
      ])
      setForm({ ...formState, image: { ...temporaryFile } })
    } else {
      if (file.status === 'error' && file.response && file.response.message) {
        message.error(file.response.message)
      }
      setPictures([...fileList.filter((x) => isFileAccepted(x))])
    }
  }

  const handleRemovePicture = (file) => {
    setPictures([...pictures.filter((x) => x.uid !== file.uid)])
    setForm({ ...formState, image: null })
  }

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }

  const handlePreview = async (picture) => {
    if (!picture.url) {
      picture.url = await getBase64(picture.originFileObj)
    }
    setPreviewPicture(picture.url)
    setModalPreviewVisible(true)
  }

  return (
    <Modal
      destroyOnClose
      maskClosable={false}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      afterClose={handleAfterClose}
    >
      <Form form={form} onSubmit={handleSubmit} layout="vertical">
        <PageTitle
          title={
            id
              ? intl.formatMessage({ id: 'albums.form.update' })
              : intl.formatMessage({ id: 'albums.form.create' })
          }
        />

        <Skeleton paragraph={false} loading={isFetching} active>
          <Form.Item
            initialValue={album.titleFr}
            label={intl.formatMessage({ id: 'albums.label.titleFr' })}
            name="titleFr"
            rules={[RULES.required]}
          >
            <Input
              placeholder={intl.formatMessage({ id: 'albums.label.titleFr' })}
              name="titleFr"
              onChange={handleChange}
            />
          </Form.Item>
        </Skeleton>

        <Skeleton paragraph={false} loading={isFetching} active>
          <Form.Item
            initialValue={album.titleEn}
            label={intl.formatMessage({ id: 'albums.label.titleEn' })}
            name="titleEn"
          >
            <Input
              placeholder={intl.formatMessage({ id: 'albums.label.titleEn' })}
              name="titleEn"
              onChange={handleChange}
            />
          </Form.Item>
        </Skeleton>

        <Skeleton paragraph={false} loading={isFetching} active>
          <Form.Item
            initialValue={album.image}
            label={intl.formatMessage({ id: 'albums.label.image' })}
            name="image"
          >
            <StyledUpload
              headers={{ Authorization: `Bearer ${localStorage.getItem('bearer')}` }}
              action={FILERENDER_URL}
              onChange={handleChangePicture}
              onRemove={handleRemovePicture}
              onPreview={handlePreview}
              method="POST"
              fileList={pictures}
              defaultFileList={pictures}
              showUploadList
              listType="picture-card"
              accept="image/*"
              beforeUpload={(file) => isFileAccepted(file)}
              maxCount={1}
            >
              <div>
                <Icon name={ICONS_LIST.plus} />
                <div className="ant-upload-text">
                  <FormattedMessage id="upload" />
                </div>
              </div>
            </StyledUpload>
          </Form.Item>
        </Skeleton>

        <Form.Item>
          <Row align="center" gutter={16}>
            <Col>
              <Button
                onClick={handleSubmit}
                htmlType="submit"
                type="primary"
                loading={isSaving}
                disabled={isSaving}
              >
                <FormattedMessage id="save" />
              </Button>
            </Col>
            <Col>
              <Button onClick={onCancel} htmlType="submit" type="secondary" disabled={isSaving}>
                <FormattedMessage id="cancel" />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>

      <Modal visible={modalPreviewVisible} footer={null} onCancel={handleCancelPreview}>
        <img alt="Preview" style={{ width: '100%' }} src={previewPicture} />
      </Modal>
    </Modal>
  )
}

AlbumModalForm.defaultProps = {
  id: null
}

AlbumModalForm.propTypes = {
  intl: PropTypes.shape().isRequired,
  visible: PropTypes.bool.isRequired,
  id: PropTypes.number,
  actions: PropTypes.shape().isRequired,
  album: PropTypes.shape().isRequired,
  onCancel: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired
}

const mapStateToProps = (state) => ({
  album: state.album,
  isFetching: loadingSelector([FETCH_ALBUM])(state),
  isSaving: loadingSelector([CREATE_ALBUM, UPDATE_ALBUM])(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    initData: async () => {
      const { id } = ownProps
      if (id) {
        await dispatch(fetchAlbum(id))
      }
    },
    resetData: async () => {
      await dispatch(resetAlbum())
    },
    onSubmit: async (form) => {
      const { id } = ownProps
      if (id) {
        await dispatch(updateAlbum(id, form))
      } else {
        await dispatch(createAlbum(form))
      }
      await dispatch(setAlbumsFilters({ offset: 0, search: null }))
      dispatch(fetchAlbums())
    }
  }
})

const withIntl = injectIntl(AlbumModalForm)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
