import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import history from 'app/history'

import svg from 'assets/svg'
import { colors } from 'assets/themes'

import { Icon, ICONS_LIST, Dropdown, Menu, Svg, Row, Col } from 'lib'

import { AUTHENTICATION_SIGN_IN } from 'utils/route/constants'

import { setSpinLoader, signOut } from 'store/actions'

const SVGWrapper = styled.div`
  padding: 10px 10px;
  margin-left: 50px;
`

const SVG = styled(Svg)`
  fill: #fff;
`

const Core = ({ actions, user }) => {
  return (
    <Row className="menu" justify="space-around" align="middle">
      <Col span={2}>
        <a target="_blank" rel="noreferrer" href="https://atelierbreze.fr">
          <SVGWrapper>
            <SVG svg={svg.logo} />
          </SVGWrapper>
        </a>
      </Col>
      <Col span={4} className="menu-title">
        Atelier Brézé - Admin Panel
      </Col>
      <Col className="menu-dropdown-wrapper">
        <Dropdown
          trigger={['hover']}
          overlay={
            <Menu className="menu-dropdown">
              <Menu.Item disabled key="1" className="menu-dropdown-user">
                <div>{user.name}</div>
              </Menu.Item>
              <Menu.Divider />
              <Menu.Item
                className="menu-dropdown-logout"
                key="2"
                onClick={actions.signOut}
                icon={<Icon name={ICONS_LIST.logout} />}
              >
                <FormattedMessage id="auth.logout" />
              </Menu.Item>
            </Menu>
          }
        >
          <Icon name={ICONS_LIST.account} color={colors.white} />
        </Dropdown>
      </Col>
    </Row>
  )
}

Core.propTypes = {
  actions: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired
}

const mapStateToProps = (state) => ({
  user: state.user
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    signOut: async () => {
      dispatch(setSpinLoader(true))
      await dispatch(signOut())
      localStorage.removeItem('bearer')
      dispatch(setSpinLoader(false))

      history.replace({ pathname: AUTHENTICATION_SIGN_IN })
    }
  }
})

const withIntl = injectIntl(Core)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
