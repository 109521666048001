import React from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'

import { colors } from 'assets/themes'

import { SectionTitle, Descriptions, Skeleton, Divider } from 'lib'

const ContactDescription = ({ intl, contact, loading, showContactForm }) => {
  const { address, zipCode, city, email, phone, facebookUrl, instagramUrl } = contact

  return (
    <Descriptions
      title={
        <>
          <SectionTitle
            title={intl.formatMessage({ id: 'contact.title' })}
            subtitle={intl.formatMessage({ id: 'contact.subtitle' })}
            subtitleDisabled={loading}
            subtitleAction={showContactForm}
          />
          <Divider height={2} colors={colors.grey} margin={8} opacity={0.5} />
        </>
      }
      column={2}
      bordered
    >
      <Descriptions.Item label={intl.formatMessage({ id: 'contact.label.address' })}>
        <Skeleton paragraph={false} loading={loading} active>
          {address}
        </Skeleton>
      </Descriptions.Item>

      <Descriptions.Item label="Facebook URL">
        <Skeleton paragraph={false} loading={loading} active>
          {facebookUrl && (
            <a target="_blank" rel="noreferrer" href={facebookUrl} className="contact-descriptions-link">
              {facebookUrl}
            </a>
          )}
        </Skeleton>
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: 'contact.label.zip_code' })}>
        <Skeleton paragraph={false} loading={loading} active>
          {zipCode}
        </Skeleton>
      </Descriptions.Item>

      <Descriptions.Item label="Instagram URL">
        <Skeleton paragraph={false} loading={loading} active>
          {instagramUrl && (
            <a target="_blank" rel="noreferrer" href={instagramUrl} className="contact-descriptions-link">
              {instagramUrl}
            </a>
          )}
        </Skeleton>
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: 'contact.label.city' })} span={2}>
        <Skeleton paragraph={false} loading={loading} active>
          {city}
        </Skeleton>
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: 'contact.label.email' })} span={2}>
        <Skeleton paragraph={false} loading={loading} active>
          {email}
        </Skeleton>
      </Descriptions.Item>

      <Descriptions.Item label={intl.formatMessage({ id: 'contact.label.phone' })} span={2}>
        <Skeleton paragraph={false} loading={loading} active>
          {phone && phone.split('').map((x, i) => (i % 2 === 0 ? ` ${x}` : x))}
        </Skeleton>
      </Descriptions.Item>
    </Descriptions>
  )
}

ContactDescription.propTypes = {
  intl: PropTypes.shape().isRequired,
  contact: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  showContactForm: PropTypes.func.isRequired
}

export default injectIntl(ContactDescription)
