import React, { useEffect, useState, useCallback } from 'react'
import { injectIntl } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { colors } from 'assets/themes'

import { SectionTitle, Input, Col, Divider, List, ConfirmDeleteModal } from 'lib'

import loadingSelector from 'utils/redux/selectors'
import { throttleSearch } from 'utils/tools/throttle'

import { fetchAlbums, setAlbumsFilters, deleteAlbum, setPublicationsFilters } from 'store/actions'
import { FETCH_ALBUMS, DELETE_ALBUM } from 'store/constants'

import AlbumModalForm from '../AlbumModalForm'
import ListItem from './ListItem'

const { Search } = Input

const AlbumsList = ({ intl, actions, albums, loading, onSelectAlbum, isDeleting }) => {
  const [albumId, setId] = useState(null)
  const [isShownModal, setShowModal] = useState(false)
  const showModal = useCallback(() => {
    setShowModal(true)
  }, [])
  const hideModal = useCallback(() => {
    setShowModal(false)
    setId(null)
  }, [])

  const [isShownDelete, setShowDelete] = useState(false)
  const showDelete = useCallback(() => setShowDelete(true), [])
  const hideDelete = useCallback(() => {
    setShowDelete(false)
    setId(null)
  }, [])

  const { filters } = albums

  useEffect(() => {
    actions.fetch()
  }, [])

  const handleSearch = (e) => {
    const { value } = e.target
    actions.setAlbumsFilters({
      search: value.length ? value.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : null
    })
    throttleSearch(() => actions.fetch())
  }

  const handleChangePagination = (page) => {
    actions.setAlbumsFilters({ offset: (page - 1) * filters.limit })
    actions.fetch()
  }

  const handleClickUpdate = (id) => {
    setId(id)
    showModal()
  }

  const handleClickDelete = (id) => {
    setId(id)
    showDelete()
  }

  const handleDeleteProxy = async (e) => {
    e.preventDefault()
    await actions.onDelete(albumId)
    hideDelete()
  }

  const handleSelectAlbum = async (id) => {
    onSelectAlbum(id)
    await actions.setPublicationsFilters({ albumId: id })
  }

  return (
    <>
      <SectionTitle
        title={intl.formatMessage({ id: 'albums.list.title' })}
        subtitle={intl.formatMessage({ id: 'albums.list.create' })}
        subtitleDisabled={loading}
        subtitleAction={showModal}
      >
        <Col span={6}>
          <Search
            defaultValue={filters.search}
            placeholder={intl.formatMessage({ id: 'albums.list.search' })}
            onChange={handleSearch}
            value={filters.search}
          />
        </Col>
      </SectionTitle>
      <Divider height={2} colors={colors.grey} margin={8} opacity={0.5} />
      <List
        loading={loading}
        dataSource={albums.items}
        pagination={{
          onChange: (page) => handleChangePagination(page),
          pageSize: 5,
          total: albums.total,
          hideOnSinglePage: true,
          current: filters.offset / filters.limit + 1
        }}
        renderItem={(item) => (
          <ListItem
            item={item}
            onSelect={handleSelectAlbum}
            onClickUpdate={handleClickUpdate}
            onClickDelete={handleClickDelete}
          />
        )}
      />

      <ConfirmDeleteModal
        visible={isShownDelete}
        message={intl.formatMessage({
          id: 'albums.form.delete'
        })}
        onCancel={hideDelete}
        onDelete={handleDeleteProxy}
        loading={isDeleting}
        intl={intl}
      />

      <AlbumModalForm id={albumId} visible={isShownModal} onCancel={hideModal} />
    </>
  )
}

AlbumsList.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  albums: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  onSelectAlbum: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  albums: state.albums,
  loading: loadingSelector([FETCH_ALBUMS])(state),
  isDeleting: loadingSelector([DELETE_ALBUM])(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    fetch: () => dispatch(fetchAlbums()),
    setAlbumsFilters: (filters) => dispatch(setAlbumsFilters(filters)),
    setPublicationsFilters: (filters) => dispatch(setPublicationsFilters(filters)),
    onDelete: async (id) => {
      await dispatch(deleteAlbum(id))
      await dispatch(setAlbumsFilters({ offset: 0, search: null }))
      dispatch(fetchAlbums())
    }
  }
})

const withIntl = injectIntl(AlbumsList)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
