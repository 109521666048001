/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux'
import thunk from 'redux-thunk'
import { routerMiddleware } from 'connected-react-router'
import {} from 'lodash'

import { persistState, persistedState } from 'utils/storage/statePersistence'
import history from './history'
import rootReducer from './reducers'

const composeEnhancers =
  typeof window === 'object' &&
  process.env.NODE_ENV === 'development' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })
    : compose

const enhancer = composeEnhancers(compose(applyMiddleware(routerMiddleware(history), thunk)))

const store = createStore(rootReducer, persistedState, enhancer)
store.subscribe(() => persistState(store.getState()))

export default store
