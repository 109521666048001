import * as constants from './constants'

const mapContact = (datas) => {
  const { address, zipCode, city, phone, email, facebookUrl, instagramUrl, updatedAt } = datas

  return {
    address,
    zipCode,
    city: city.toUpperCase(),
    phone,
    email,
    instagramUrl,
    facebookUrl,
    updatedAt
  }
}

const mapAlbum = (datas) => {
  const { id, titleFr, titleEn, image, updatedAt } = datas

  return {
    id,
    titleFr,
    titleEn,
    image: image && {
      name: image.name,
      fileExtension: image.fileExtension,
      createdAt: image.createdAt
    },
    updatedAt
  }
}

const mapPublication = (datas) => {
  const { id, descriptionFr, descriptionEn, images, updatedAt } = datas

  return {
    id,
    descriptionFr,
    descriptionEn,
    images: images.map((image) =>
      image.id
        ? { ...image }
        : {
            name: image.response.temporaryFile.name,
            fileExtension: image.response.temporaryFile.fileExtension,
            createdAt: image.response.temporaryFile.createdAt
          }
    ),
    updatedAt
  }
}

const mapCommonQueryParams = (datas) => {
  const { total, limit, offset, search } = datas

  return {
    total,
    limit,
    offset,
    search
  }
}

const mapPublicationQueryParams = (datas) => {
  const { albumId } = datas

  return {
    albumId,
    ...mapCommonQueryParams(datas)
  }
}

export default (method, datas) => {
  switch (method) {
    case constants.UPDATE_CONTACT:
      return mapContact(datas)
    case constants.CREATE_ALBUM:
    case constants.UPDATE_ALBUM:
      return mapAlbum(datas)
    case constants.FETCH_ALBUMS:
      return mapCommonQueryParams(datas)
    case constants.CREATE_PUBLICATION:
    case constants.UPDATE_PUBLICATION:
      return mapPublication(datas)
    case constants.FETCH_PUBLICATIONS:
      return mapPublicationQueryParams(datas)
    default:
      return null
  }
}
