import styled from 'styled-components'
import { Typography } from 'antd'
import { colors } from 'assets/themes'

const AntTypographyStyled = styled(Typography)``
AntTypographyStyled.Paragraph = styled(Typography.Paragraph)`
  .ant-typography-expand {
    color: ${colors.red};
    text-decoration: underline;
    &:hover {
      color: ${colors.grey};
      text-decoration: underline;
    }
  }
`

export default AntTypographyStyled
