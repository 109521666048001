import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BaseLayout, Header } from 'lib'

const ContentLayer = styled.div`
  background: #fff;
  padding: 25px;
  min-height: 280px;
`

const ContentLayout = ({ children }) => {
  return (
    <BaseLayout style={{ flex: '1' }}>
      <BaseLayout.Header>
        <Header.Core />
      </BaseLayout.Header>
      <BaseLayout.SubHeader>
        <Header.Sub />
      </BaseLayout.SubHeader>
      <BaseLayout.Content>
        <ContentLayer>{children}</ContentLayer>
      </BaseLayout.Content>
      <BaseLayout.Footer>
        ©2021 Atelier Brézé - Admin Panel {'| '}
        <a target="_blank" rel="noreferrer" href="https://benjamin-alexandre.fr">
          Benjamin Alexandre
        </a>
      </BaseLayout.Footer>
    </BaseLayout>
  )
}

ContentLayout.propTypes = {
  children: PropTypes.node.isRequired
}

export default ContentLayout
