import React, { useEffect, useState, useCallback } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { colors } from 'assets/themes'

import { Skeleton, Button, SectionTitle, Divider, List, ConfirmDeleteModal, Breadcrumb } from 'lib'

import loadingSelector from 'utils/redux/selectors'

import { fetchAlbum, fetchPublications, setPublicationsFilters, deletePublication } from 'store/actions'
import { FETCH_ALBUM, FETCH_PUBLICATIONS, DELETE_PUBLICATION } from 'store/constants'

import PublicationModalForm from '../PublicationModalForm'
import PublicationModal from '../PublicationModal'
import ListItem from './ListItem'

const PublicationsList = ({
  intl,
  actions,
  publications,
  album,
  loading,
  isFetchingAlbum,
  onClickGoBack,
  isDeleting
}) => {
  const [publicationId, setId] = useState(null)
  const [isShownModal, setShowModal] = useState(false)
  const showModal = useCallback(() => {
    setShowModal(true)
  }, [])
  const hideModal = useCallback(() => {
    setShowModal(false)
    setId(null)
  }, [])

  const [isShownDelete, setShowDelete] = useState(false)
  const showDelete = useCallback(() => setShowDelete(true), [])
  const hideDelete = useCallback(() => {
    setShowDelete(false)
    setId(null)
  }, [])

  const [isShownDetail, setShowDetail] = useState(false)
  const showDetail = useCallback(() => setShowDetail(true), [])
  const hideDetail = useCallback(() => {
    setShowDetail(false)
    setId(null)
  }, [])

  const { filters } = publications

  useEffect(() => {
    actions.initData()
    actions.fetch()
  }, [])

  const handleChangePagination = (page) => {
    actions.setPublicationsFilters({ offset: (page - 1) * filters.limit })
    actions.fetch()
  }

  const handleClickUpdate = (id) => {
    setId(id)
    showModal()
  }

  const handleClickDelete = (id) => {
    setId(id)
    showDelete()
  }

  const handleDeleteProxy = async (e) => {
    e.preventDefault()
    await actions.onDelete(publicationId)
    hideDelete()
  }

  const onSelectPublication = (id) => {
    setId(id)
    showDetail()
  }

  return (
    <>
      <Button type="link" onClick={onClickGoBack}>
        <FormattedMessage id="go_back" />
      </Button>
      <Skeleton paragraph={false} loading={isFetchingAlbum} active>
        <Breadcrumb separator=">">
          <Breadcrumb.Item>
            <FormattedMessage id="albums.list.title" />
          </Breadcrumb.Item>
          <Breadcrumb.Item>{album.titleFr}</Breadcrumb.Item>
          <Breadcrumb.Item>
            <FormattedMessage id="publications.list.title" />
          </Breadcrumb.Item>
        </Breadcrumb>
      </Skeleton>

      <SectionTitle
        title={intl.formatMessage({ id: 'publications.list.title' })}
        subtitle={intl.formatMessage({ id: 'publications.list.create' })}
        subtitleDisabled={loading}
        subtitleAction={showModal}
      />

      <Divider height={2} colors={colors.grey} margin={8} opacity={0.5} />

      <List
        loading={loading}
        dataSource={publications.items}
        pagination={{
          onChange: (page) => handleChangePagination(page),
          pageSize: 5,
          total: publications.total,
          hideOnSinglePage: true,
          current: filters.offset / filters.limit + 1
        }}
        renderItem={(item) => (
          <ListItem
            item={item}
            onSelect={onSelectPublication}
            onClickUpdate={handleClickUpdate}
            onClickDelete={handleClickDelete}
          />
        )}
      />

      <ConfirmDeleteModal
        visible={isShownDelete}
        message={intl.formatMessage({
          id: 'albums.form.delete'
        })}
        onCancel={hideDelete}
        onDelete={handleDeleteProxy}
        loading={isDeleting}
        intl={intl}
      />

      <PublicationModalForm id={publicationId} visible={isShownModal} onCancel={hideModal} />
      <PublicationModal id={publicationId} visible={isShownDetail} onCancel={hideDetail} />
    </>
  )
}

PublicationsList.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  publications: PropTypes.shape().isRequired,
  album: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isFetchingAlbum: PropTypes.bool.isRequired,
  onClickGoBack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  publications: state.publications,
  album: state.album,
  loading: loadingSelector([FETCH_PUBLICATIONS])(state),
  isDeleting: loadingSelector([DELETE_PUBLICATION])(state),
  isFetchingAlbum: loadingSelector([FETCH_ALBUM])(state)
})

const mapDispatchToProps = (dispatch, ownProps) => ({
  actions: {
    initData: async () => {
      const { id } = ownProps
      await dispatch(fetchAlbum(id))
    },
    fetch: () => dispatch(fetchPublications()),
    setPublicationsFilters: (filters) => dispatch(setPublicationsFilters(filters)),
    onDelete: async (id) => {
      await dispatch(deletePublication(id))
      await dispatch(setPublicationsFilters({ offset: 0, search: null }))
      dispatch(fetchPublications())
    }
  }
})

const withIntl = injectIntl(PublicationsList)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
