import requestWrapper, { METHOD } from 'utils/redux/request'
import * as constants from './constants'
import map from './mapper'

export const changeLanguage = (locale) => ({
  type: constants.CHANGE_LANGUAGE,
  payload: locale
})

export const setSpinLoader = (value) => ({
  type: constants.SET_SPIN_LOADER,
  payload: value
})

export const signIn = (command) => async (dispatch) => {
  await dispatch(
    requestWrapper(
      METHOD.POST,
      '/auth/sign-in',
      constants.SIGN_IN,
      { ...command, applicationId: constants.APPLICATION_ID },
      { name: command.login },
      true
    )
  )
}

export const signOut = () => async (dispatch) => {
  await dispatch(requestWrapper(METHOD.POST, '/auth/sign-out', constants.SIGN_OUT))
}

export const fetchContact = () => async (dispatch) => {
  await dispatch(requestWrapper(METHOD.GET, '/contact', constants.FETCH_CONTACT))
}

export const updateContact = (command) => async (dispatch) => {
  await dispatch(
    requestWrapper(METHOD.PUT, '/contact', constants.UPDATE_CONTACT, {
      contact: map(constants.UPDATE_CONTACT, command)
    })
  )
}

export const fetchAlbums = () => async (dispatch) => {
  await dispatch((_, getState) => {
    const { albums } = getState()
    dispatch(
      requestWrapper(
        METHOD.GET,
        '/albums',
        constants.FETCH_ALBUMS,
        map(constants.FETCH_ALBUMS, albums.filters)
      )
    )
  })
}

export const setAlbumsFilters = (value) => ({
  type: constants.SET_ALBUMS_FILTERS,
  payload: value
})

export const createAlbum = (command) => async (dispatch) => {
  await dispatch(
    requestWrapper(METHOD.POST, '/albums', constants.CREATE_ALBUM, {
      album: map(constants.CREATE_ALBUM, command)
    })
  )
}

export const fetchAlbum = (id) => async (dispatch) => {
  await dispatch(requestWrapper(METHOD.GET, `/albums/${id}`, constants.FETCH_ALBUM))
}

export const resetAlbum = () => ({
  type: constants.RESET_ALBUM
})

export const updateAlbum = (id, command) => async (dispatch) => {
  await dispatch(
    requestWrapper(METHOD.PUT, `/albums/${id}`, constants.UPDATE_ALBUM, {
      album: map(constants.UPDATE_ALBUM, command)
    })
  )
}

export const deleteAlbum = (id) => async (dispatch) => {
  await dispatch(requestWrapper(METHOD.DELETE, `/albums/${id}`, constants.DELETE_ALBUM))
}

export const fetchPublications = () => async (dispatch) => {
  await dispatch((_, getState) => {
    const { publications } = getState()
    dispatch(
      requestWrapper(
        METHOD.GET,
        '/publications',
        constants.FETCH_PUBLICATIONS,
        map(constants.FETCH_PUBLICATIONS, publications.filters)
      )
    )
  })
}

export const setPublicationsFilters = (value) => ({
  type: constants.SET_PUBLICATIONS_FILTERS,
  payload: value
})

export const createPublication = (command, albumId) => async (dispatch) => {
  await dispatch(
    requestWrapper(METHOD.POST, '/publications', constants.CREATE_PUBLICATION, {
      publication: map(constants.CREATE_PUBLICATION, command),
      albumId
    })
  )
}

export const fetchPublication = (id) => async (dispatch) => {
  await dispatch(requestWrapper(METHOD.GET, `/publications/${id}`, constants.FETCH_PUBLICATION))
}

export const resetPublication = () => ({
  type: constants.RESET_PUBLICATION
})

export const updatePublication = (id, command) => async (dispatch) => {
  await dispatch(
    requestWrapper(METHOD.PUT, `/publications/${id}`, constants.UPDATE_PUBLICATION, {
      publication: map(constants.UPDATE_PUBLICATION, command)
    })
  )
}

export const deletePublication = (id) => async (dispatch) => {
  await dispatch(requestWrapper(METHOD.DELETE, `/publications/${id}`, constants.DELETE_PUBLICATION))
}
