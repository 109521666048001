import React from 'react'
import PropTypes from 'prop-types'
import ScrollAnimation from 'react-animate-on-scroll'

const ScrollAnimationComponent = ({ children, ...props }) => {
  return <ScrollAnimation {...props}>{children}</ScrollAnimation>
}

ScrollAnimationComponent.defaultProps = {
  animateOnce: true
}

ScrollAnimationComponent.propTypes = {
  children: PropTypes.node,
  animateOnce: PropTypes.bool
}

export default ScrollAnimationComponent
