import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Editor as DraftEditor, EditorState, RichUtils } from 'draft-js'
import { convertToHTML, convertFromHTML } from 'draft-convert'
import styled from 'styled-components'
import { colors } from 'assets/themes'

const RichEditorWrapper = styled.div`
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;
`

const EditorWrapper = styled.div`
  border-top: 1px solid #ddd;
  cursor: text;
  font-size: 16px;
  margin-top: 10px;
  min-height: 200px;
`

const ButtonWrapper = styled.span`
  color: #999;
  cursor: pointer;
  margin-right: 16px;
  padding: 2px 0;
  display: inline-block;
  &&&.active {
    color: ${colors.red};
  }
`

const ControlsWrapper = styled.div`
  font-size: 14px;
  margin-bottom: 5px;
  user-select: none;
`

const StyleButton = (props) => {
  const handleToggle = (e) => {
    e.preventDefault()
    props.onToggle(props.style)
  }

  return (
    <ButtonWrapper
      className={props.active && 'active'}
      onMouseDown={handleToggle}
      role="button"
      tabIndex={0}
      style={props.buttonStyle}
    >
      {props.label}
    </ButtonWrapper>
  )
}
StyleButton.propTypes = {
  onToggle: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  style: PropTypes.string.isRequired,
  active: PropTypes.bool.isRequired,
  buttonStyle: PropTypes.shape
}

const INLINE_STYLES = [
  { label: 'Gras', style: 'BOLD', buttonStyle: { fontWeight: 600 } },
  { label: 'Italique', style: 'ITALIC', buttonStyle: { fontStyle: 'italic' } },
  { label: 'Souligné', style: 'UNDERLINE', buttonStyle: { textDecoration: 'underline' } }
]
const InlineStyleControls = (props) => {
  const currentStyle = props.editorState.getCurrentInlineStyle()

  return (
    <ControlsWrapper>
      {INLINE_STYLES.map((type) => (
        <StyleButton
          key={type.label}
          active={currentStyle.has(type.style)}
          label={type.label}
          onToggle={props.onToggle}
          style={type.style}
          buttonStyle={type.buttonStyle}
        />
      ))}
    </ControlsWrapper>
  )
}
InlineStyleControls.propTypes = {
  editorState: PropTypes.shape().isRequired,
  onToggle: PropTypes.func.isRequired
}

const Editor = (props) => {
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty())

  useEffect(() => {
    if (props.defaultValue) setEditorState(EditorState.push(editorState, convertFromHTML(props.defaultValue)))
  }, [props.defaultValue])

  const toggleInlineStyle = (inlineStyle) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyle))
  }

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      setEditorState(newState)
      return true
    }
    return false
  }

  const onTab = (e) => {
    setEditorState(RichUtils.onTab(e, editorState, 4))
  }

  const onBlur = () => {
    props.onChange({ target: { name: props.name, value: convertToHTML(editorState.getCurrentContent()) } })
  }

  return (
    <RichEditorWrapper>
      <InlineStyleControls editorState={editorState} onToggle={toggleInlineStyle} />
      <EditorWrapper>
        <DraftEditor
          editorState={editorState}
          handleKeyCommand={handleKeyCommand}
          onChange={setEditorState}
          onBlur={onBlur}
          onTab={onTab}
          placeholder={props.placeholder}
          spellCheck
        />
      </EditorWrapper>
    </RichEditorWrapper>
  )
}

Editor.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired
}

export default Editor
