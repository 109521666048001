import styled from 'styled-components'
import { Layout } from 'antd'

import { colors } from 'assets/themes'

const AntLayoutStyled = styled(Layout)``

AntLayoutStyled.Header = styled(Layout.Header)`
  background: ${colors.grey};
  height: 100%;
  line-height: inherit;
  padding: 0;
`

AntLayoutStyled.SubHeader = styled(Layout.Header)`
  height: 100%;
  background: transparent;
`

AntLayoutStyled.Content = styled(Layout.Content)`
  padding: 0 250px;
`

AntLayoutStyled.Footer = styled(Layout.Footer)`
  text-align: center;
`

export default AntLayoutStyled
