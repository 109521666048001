import styled from 'styled-components'
import { Button } from 'antd'
import { colors, font, borderRadius } from 'assets/themes'

const linkType = 'link'
const secondaryType = 'secondary'

const AntButtonStyled = styled(Button)`
  text-transform: ${(props) => (props.type !== linkType && !props.small ? 'uppercase' : 'none')};
  cursor: pointer;
  font-size: ${(props) => (props.type !== linkType ? font.size.lg : font.size.sm)};
  background: ${(props) => {
    if (props.type === linkType) return 'none'
    return props.type === secondaryType ? colors.grey : colors.red
  }};
  color: ${(props) => (props.type !== linkType ? 'white' : colors.grey)};
  border: ${(props) => {
    if (props.type === linkType) return 'none'
    return props.type === secondaryType ? `2px ${colors.grey} solid` : `2px ${colors.red} solid`
  }};
  padding: ${(props) => (props.small ? 'none' : '10px')};
  border-radius: ${(props) => (props.type !== linkType ? borderRadius.md : '0')};
  transition: 0.3s ease-in-out;
  height: max-content;
  > span {
    text-decoration-line: ${(props) => (props.type !== linkType ? 'none' : 'underline')};
  }
  &:focus {
    background: ${(props) => {
      if (props.type === linkType) return 'none'
      return props.type === secondaryType ? colors.grey : colors.red
    }};
    color: ${(props) => (props.type !== linkType ? 'white' : colors.red)};
    border: ${(props) => {
      if (props.type === linkType) return 'none'
      return props.type === secondaryType ? `2px ${colors.grey} solid` : `2px ${colors.red} solid`
    }};
  }
  &:hover,
  &:active {
    border: ${(props) => {
      if (props.type === linkType) return 'none'
      return props.type === secondaryType ? `2px ${colors.grey} solid` : `2px ${colors.red} solid`
    }};
    color: ${(props) => (props.type === secondaryType ? colors.grey : colors.red)};
    background: transparent;
  }
`

export default AntButtonStyled
