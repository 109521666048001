import React, { useEffect, useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'

import history from 'app/history'

import { Menu, Link } from 'lib'
import { HOME, CONTACT, GALLERY } from 'utils/route/constants'

const Sub = () => {
  const [selectedKey, setSelectedKey] = useState('home')

  useEffect(() => {
    const key = history.location.pathname

    switch (key) {
      case CONTACT:
        setSelectedKey('contact')
        break
      case GALLERY:
        setSelectedKey('gallery')
        break
      default:
        setSelectedKey('home')
        break
    }
  }, [history.location.pathname])

  return (
    <Menu mode="horizontal" selectedKeys={[selectedKey]}>
      <Menu.Item key="home">
        <Link to={HOME}>
          <FormattedMessage id="menu.home" />
        </Link>
      </Menu.Item>
      <Menu.Item key="contact">
        {selectedKey === 'contact' ? (
          <FormattedMessage id="menu.contact" />
        ) : (
          <Link to={CONTACT}>
            <FormattedMessage id="menu.contact" />
          </Link>
        )}
      </Menu.Item>
      <Menu.Item key="gallery">
        {selectedKey === 'gallery' ? (
          <FormattedMessage id="menu.galleries" />
        ) : (
          <Link to={GALLERY}>
            <FormattedMessage id="menu.galleries" />
          </Link>
        )}
      </Menu.Item>
    </Menu>
  )
}

Sub.propTypes = {}

export default injectIntl(Sub)
