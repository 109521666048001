import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'
import { ConfigProvider } from 'antd'
import { IntlProvider as ParentIntlProvider } from 'react-intl'
import frFR from 'antd/es/locale/fr_FR'
import enGb from 'antd/es/locale/en_GB'
import 'moment/locale/fr'
import 'moment/locale/en-gb'
import loadTranslations from './loadTranslations'
import * as fr from './translations/fr_FR.json'

const IntlProvider = (props) => {
  const [messages, setMessages] = useState(fr)

  const changeLocale = async () => {
    setMessages(await loadTranslations(props.locale))
  }

  useEffect(() => {
    changeLocale()
  }, [props.locale])

  let antdLocale = null
  let intlLocale = null

  switch (props.locale) {
    case 'fr':
      antdLocale = frFR
      intlLocale = 'fr'
      moment.locale('fr')
      break
    default:
    case 'en':
      antdLocale = enGb
      moment.locale('en')
      intlLocale = 'en'
      break
  }

  return (
    <ParentIntlProvider
      textComponent="span"
      locale={intlLocale}
      defaultLocale={props.locale}
      messages={messages && messages.default}
    >
      <ConfigProvider locale={antdLocale}>{props.children}</ConfigProvider>
    </ParentIntlProvider>
  )
}

IntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
  locale: PropTypes.string
}

const mapStateToProps = (state) => ({
  locale: state.language
})

export default connect(mapStateToProps)(IntlProvider)
