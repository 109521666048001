import lang from 'utils/intl/lang'
import { Success } from 'utils/redux/actionPattern'
import { message } from 'lib'

import * as constants from './constants'

export const language = (state = lang.FR, action) => {
  switch (action.type) {
    case constants.CHANGE_LANGUAGE:
      return action.payload
    default:
      return state
  }
}

export const loadingReducer = (state = {}, action) => {
  const { type } = action
  const matches = /(.*)_(REQUEST|SUCCESS|FAILURE)/.exec(type)
  if (!matches) return state

  const [, requestName, requestState] = matches
  return {
    ...state,
    [requestName]: requestState === 'REQUEST'
  }
}

export const errorReducer = (state = {}, action) => {
  const { error, type, payload } = action
  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type)
  if (!matches) return state
  const [, , requestState] = matches

  if (error) {
    message.error(payload.response.data.message)
  }

  return requestState === 'FAILURE' ? payload : ''
}

export const authentication = (state = { token: null }, action) => {
  switch (action.type) {
    case Success(constants.SIGN_IN):
      return {
        ...state,
        token: action.payload.token
      }
    case Success(constants.SIGN_OUT):
      return {
        ...state,
        token: null
      }
    default:
      return state
  }
}

export const user = (state = {}, action) => {
  switch (action.type) {
    case Success(constants.SIGN_IN):
      return {
        ...state,
        ...action.context
      }
    case Success(constants.SIGN_OUT):
      return {}
    default:
      return state
  }
}

export const spinLoader = (state = false, action) => {
  switch (action.type) {
    case constants.SET_SPIN_LOADER:
      return action.payload
    default:
      return state
  }
}

export const contact = (state = {}, action) => {
  switch (action.type) {
    case Success(constants.FETCH_CONTACT):
      return {
        ...state,
        ...action.payload.contact
      }
    default:
      return state
  }
}

const defaultAlbumsReducer = {
  items: [],
  total: null,
  filters: {
    limit: 5,
    offset: 0,
    total: true,
    search: null
  }
}
export const albums = (state = defaultAlbumsReducer, action) => {
  switch (action.type) {
    case Success(constants.FETCH_ALBUMS):
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total
      }
    case constants.SET_ALBUMS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export const album = (state = {}, action) => {
  switch (action.type) {
    case Success(constants.FETCH_ALBUM):
      return {
        ...state,
        ...action.payload.album
      }
    case constants.RESET_ALBUM:
      return {}
    default:
      return state
  }
}

const defaultPublicationsReducer = {
  items: [],
  total: null,
  filters: {
    albumId: null,
    limit: 5,
    offset: 0,
    total: true,
    search: null
  }
}
export const publications = (state = defaultPublicationsReducer, action) => {
  switch (action.type) {
    case Success(constants.FETCH_PUBLICATIONS):
      return {
        ...state,
        items: action.payload.items,
        total: action.payload.total
      }
    case constants.SET_PUBLICATIONS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload
        }
      }
    default:
      return state
  }
}

export const publication = (state = { images: [] }, action) => {
  switch (action.type) {
    case Success(constants.FETCH_PUBLICATION):
      return {
        ...state,
        ...action.payload.publication
      }
    case constants.RESET_PUBLICATION:
      return { images: [] }
    default:
      return state
  }
}
