import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

import history from 'app/history'
import { AUTHENTICATION_SIGN_IN } from '../constants'

const PrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    if (!localStorage.getItem('bearer')) history.push(AUTHENTICATION_SIGN_IN)
  }, [])

  return <Route {...rest} render={(props) => <Component {...props} {...rest} />} />
}

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired
}

export default PrivateRoute
