import React from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import moment from 'moment'
import styled from 'styled-components'

import { Row, Col, List, Icon, ICONS_LIST, ICONS_MARGIN, ICONS_SIZE, Button } from 'lib'
import img from 'assets/images'

const { noImage } = img

export const ColImageWrapper = styled(Col)`
  div {
    width: 140px;
    height: 140px;
    text-align: center;
    margin: auto;
  }
  img {
    height: 100%;
    object-fit: contain;
  }
`

const ListItem = ({ item, onSelect, onClickUpdate, onClickDelete }) => {
  const { id, titleFr, titleEn, totalPublications, image, createdAt, updatedAt } = item

  const handleSelect = (e) => {
    e.stopPropagation()
    onSelect(id)
  }

  const handleUpdate = (e) => {
    e.stopPropagation()
    onClickUpdate(id)
  }

  const handleDelete = (e) => {
    e.stopPropagation()
    onClickDelete(id)
  }

  return (
    <List.Item key={id} onClick={handleSelect}>
      <div className="hoverable" style={{ width: '100%' }}>
        <Row justify="space-between" align="middle" gutter={18}>
          <ColImageWrapper span={4}>
            <div>
              {image ? (
                <img src={`${image.fileUrl}`} alt={image.name} />
              ) : (
                <img src={noImage.url} alt={noImage.alt} />
              )}
            </div>
          </ColImageWrapper>
          <Col span={8}>
            <h4>{titleFr}</h4>
            <span>{titleEn}</span>
          </Col>
          <Col span={6}>
            <div style={{ fontWeight: totalPublications ? 'bold' : 'normal' }}>
              <Icon name={ICONS_LIST.publications} margin={ICONS_MARGIN.right} size={ICONS_SIZE.small} />
              <FormattedMessage id="albums.list.total_publications" values={{ value: totalPublications }} />
            </div>
            <div>
              <Icon name={ICONS_LIST.clock} margin={ICONS_MARGIN.right} size={ICONS_SIZE.small} />
              {updatedAt === createdAt ? (
                <FormattedMessage id="albums.list.created" values={{ time: moment(createdAt).fromNow() }} />
              ) : (
                <FormattedMessage id="albums.list.updated" values={{ time: moment(updatedAt).fromNow() }} />
              )}
            </div>
          </Col>
          <Col span={6}>
            <Row align="center" gutter={16}>
              <Col>
                <Button onClick={handleUpdate} htmlType="submit" type="primary" small="true">
                  <FormattedMessage id="albums.list.update" />
                </Button>
              </Col>
              <Col>
                <Button onClick={handleDelete} htmlType="submit" type="secondary" small="true">
                  <FormattedMessage id="albums.list.delete" />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </List.Item>
  )
}

ListItem.propTypes = {
  item: PropTypes.shape().isRequired,
  onSelect: PropTypes.func.isRequired,
  onClickUpdate: PropTypes.func.isRequired,
  onClickDelete: PropTypes.func.isRequired
}

export default injectIntl(ListItem)
