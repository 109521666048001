import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'

import { Layout, SectionTitle } from 'lib'

const Home = ({ intl }) => {
  return (
    <Layout>
      <SectionTitle title={intl.formatMessage({ id: 'home.title' })} />
      <FormattedMessage id="home.descriptions" />
      <br />
      <FormattedMessage id="home.usefull_link" /> :
      <ul style={{ marginLeft: '50px' }}>
        <li>
          Lien vers mon site :{' '}
          <a href="https://atelierbreze.fr" target="_blank" rel="noreferrer">
            atelierbreze.fr
          </a>
        </li>
        <li>
          <a href="https://www.ovh.com/manager/" target="_blank" rel="noreferrer">
            Panel de gestion OVH
          </a>
        </li>
        <li>
          <a href="https://dashboard.emailjs.com/" target="_blank" rel="noreferrer">
            Gestion des emails
          </a>
        </li>
        <li>
          <a href="https://business.google.com/" target="_blank" rel="noreferrer">
            Google my Business
          </a>
        </li>
        <li>
          <a
            href="https://docs.google.com/document/d/1GPdUDMHXX_hwrX6i80P3zs9BaRXxAz7W6AkTLOCGuHw/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            Tous mes accès
          </a>
        </li>
      </ul>
    </Layout>
  )
}

Home.propTypes = {
  intl: PropTypes.shape().isRequired
}

export default injectIntl(Home)
