import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledSvg = styled.svg``

const SVG = (props) => {
  const { svg, children } = props
  const { viewBox, description } = svg

  return (
    <StyledSvg viewBox={viewBox} {...props}>
      {description}
      {children}
    </StyledSvg>
  )
}

SVG.propTypes = {
  svg: PropTypes.shape({
    viewBox: PropTypes.string,
    description: PropTypes.object.isRequired
  }).isRequired,
  children: PropTypes.node
}

export default SVG
