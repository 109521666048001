import React, { useEffect, useState } from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { Form, SectionTitle, Input, Row, Col, Button } from 'lib'

import loadingSelector from 'utils/redux/selectors'

import { fetchContact, updateContact } from 'store/actions'
import { UPDATE_CONTACT } from 'store/constants'

const Contact = ({ intl, actions, contact, loading, onCancel }) => {
  const [form] = Form.useForm()
  const [formState, setForm] = useState({})

  useEffect(() => {
    setForm({ ...contact })
  }, [contact])

  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...formState,
      [name]: value
    })
  }

  const handleCancel = () => {
    setForm({})
    onCancel()
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    await actions.onSubmit(formState)
    handleCancel()
  }

  return (
    <>
      <SectionTitle title={intl.formatMessage({ id: 'contact.modify' })} />

      <Form form={form} layout="vertical" requiredMark={false} onFinish={handleSubmit}>
        <Form.Item
          initialValue={contact.address}
          label={intl.formatMessage({ id: 'contact.label.address' })}
          name="address"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.label.address' })}
            name="address"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          initialValue={contact.zipCode}
          label={intl.formatMessage({ id: 'contact.label.zip_code' })}
          name="zipCode"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.label.zip_code' })}
            name="zipCode"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          initialValue={contact.city}
          label={intl.formatMessage({ id: 'contact.label.city' })}
          name="city"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.label.city' })}
            name="city"
            onChange={handleChange}
            style={{ textTransform: 'uppercase' }}
          />
        </Form.Item>

        <Form.Item
          initialValue={contact.email}
          label={intl.formatMessage({ id: 'contact.label.email' })}
          name="email"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.label.email' })}
            name="email"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item
          initialValue={contact.phone}
          label={intl.formatMessage({ id: 'contact.label.phone' })}
          name="phone"
        >
          <Input
            placeholder={intl.formatMessage({ id: 'contact.label.phone' })}
            name="phone"
            onChange={handleChange}
          />
        </Form.Item>

        <Form.Item initialValue={contact.facebookUrl} label="Facebook URL" name="facebookUrl">
          <Input placeholder="Facebook URL" name="facebookUrl" onChange={handleChange} />
        </Form.Item>

        <Form.Item initialValue={contact.instagramUrl} label="Instagram URL" name="instagramUrl">
          <Input placeholder="Instagram URL" name="instagramUrl" onChange={handleChange} />
        </Form.Item>

        <Form.Item>
          <Row align="center" gutter={16}>
            <Col>
              <Button onClick={handleSubmit} htmlType="submit" type="primary" loading={loading}>
                <FormattedMessage id="save" />
              </Button>
            </Col>
            <Col>
              <Button onClick={handleCancel} htmlType="submit" type="secondary">
                <FormattedMessage id="cancel" />
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}

Contact.propTypes = {
  intl: PropTypes.shape().isRequired,
  actions: PropTypes.shape().isRequired,
  contact: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  contact: state.contact,
  loading: loadingSelector([UPDATE_CONTACT])(state)
})

const mapDispatchToProps = (dispatch) => ({
  actions: {
    onSubmit: async (form) => {
      await dispatch(updateContact(form))
      dispatch(fetchContact())
    }
  }
})

const withIntl = injectIntl(Contact)
export default connect(mapStateToProps, mapDispatchToProps)(withIntl)
