import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { Alert, Modal, Button, Col, Row, PageTitle } from 'lib'

const ConfirmDelete = ({ intl, visible, onCancel, message, onDelete, loading }) => (
  <Modal destroyOnClose maskClosable={false} visible={visible} onCancel={onCancel} footer={null}>
    <PageTitle title={intl.formatMessage({ id: 'delete.confirmation' })} />
    <section>
      <Alert showIcon type="warning" message={message} />
    </section>

    <Row justify="center" gutter={16}>
      <Col>
        <Button
          onClick={onDelete}
          htmlType="submit"
          type="primary"
          loading={loading}
          small="true"
          disabled={loading}
        >
          <FormattedMessage id="yes" />
        </Button>
      </Col>
      <Col>
        <Button onClick={onCancel} htmlType="submit" type="secondary" small="true" disabled={loading}>
          <FormattedMessage id="no" />
        </Button>
      </Col>
    </Row>
  </Modal>
)

ConfirmDelete.defaultProps = {
  message: null,
  loading: false
}

ConfirmDelete.propTypes = {
  intl: PropTypes.any.isRequired,
  visible: PropTypes.bool.isRequired,
  message: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool
}

export default ConfirmDelete
