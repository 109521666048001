import React, { useEffect, useState } from 'react'

import { Layout } from 'lib'

import { GALLERY_STEPS } from 'store/constants'

import AlbumsList from './containers/AlbumsList'
import PublicationsList from './containers/PublicationsList'

const Gallery = () => {
  const [currentStep, setCurrentStep] = useState(GALLERY_STEPS.ALBUMS_LIST)
  const [currentAlbumId, setCurrentAlbumId] = useState(null)

  useEffect(() => {
    setCurrentStep(GALLERY_STEPS.ALBUMS_LIST)
    setCurrentAlbumId(null)
  }, [])

  const onClickAlbum = (id) => {
    setCurrentStep(GALLERY_STEPS.PUBLICATIONS_LIST)
    setCurrentAlbumId(id)
  }

  const onClickGoBack = () => {
    setCurrentStep(GALLERY_STEPS.ALBUMS_LIST)
    setCurrentAlbumId(null)
  }

  return (
    <Layout>
      {currentStep === GALLERY_STEPS.ALBUMS_LIST && <AlbumsList onSelectAlbum={onClickAlbum} />}
      {currentStep === GALLERY_STEPS.PUBLICATIONS_LIST && (
        <PublicationsList id={currentAlbumId} onClickGoBack={onClickGoBack} />
      )}
    </Layout>
  )
}

export default Gallery
