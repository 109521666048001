import styled from 'styled-components'
import { Form } from 'antd'

const AntFormStyled = styled(Form)`
  .ant-form-item-label {
    > * {
      text-transform: uppercase;
      font-size: 1rem;
      font-weight: 600;
    }
  }
`

export default AntFormStyled
